body {
    font-family: 'Roboto', sans-serif;
}

html,
body,
#root {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    @media screen and (max-width: 1024px) {
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
        ::-webkit-scrollbar { 
            display: none;  /* Safari and Chrome */
        }
    }
}

.logo-font {
    font-family: 'LogoFont', 'Roboto', sans-serif;
}

#root textarea,
#root input,
#root button {
    outline: none;
}

.baustein-client-widget > div {
    z-index: 2;
}

.rbc-agenda-date-cell {
    white-space: pre !important;
}


::-webkit-scrollbar {
    height: 6px !important;              /* height of horizontal scrollbar ← You're missing this */
    width: 4px;                         /* width of vertical scrollbar */
    background: rgba(0, 0, 0, 0.1) !important;
    border-radius: 4px !important;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px !important;
    background-color: rgba(0, 0, 0, 0.4) !important;
}

.bg-play-image {
    background-image: url('./assets/play-background.png');
}

.slick-track {
    margin-left: unset !important;
}